import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import ButtonTag from "../components/UI/ButtonTag"

const ProjectsPage = ({ data, location }) => {
  const projects = data.projects.edges

  return (
    <Layout path={location.pathname}>
      <SiteMetadata
        title="Projects"
        description="List of MADE BY RISE projects"
      />
      <div className="container px-16 pb-20 lg:pb-32 pt-32">
        <h2 className="text-mbr_orange mb-8">Projects</h2>
        {projects &&
          projects.map((project, index) => {
            return (
              <div
                key={`projects-key-${index}`}
                className="mb-24 section h-full w-full text-xs text-mbr_blue hover:text-mbr_orange"
              >
                <Link
                  to={`/projects/${project.node.slug}`}
                  className="font-bold"
                >
                  <Img
                    fluid={project.node.screenshots[0].fluid}
                    alt={project.node.name}
                    className="my-4"
                  />
                  {project.node.tag &&
                    project.node.tag.map(item => <ButtonTag content={item} />)}
                  <h3 className="mt-4">{project.node.name}</h3>
                  <p className="small ">{project.node.description}</p>
                </Link>
              </div>
            )
          })}
      </div>
    </Layout>
  )
}

export default ProjectsPage

export const query = graphql`
  query {
    projects: allContentfulProjects(sort: {order: DESC, fields: launchDate}) {
      edges {
        node {
          bannerImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          mobileBannerImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          screenshots {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          name
          description
          slug
          tag
        }
      }
    }
  }
`
