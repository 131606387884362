import React from 'react'

const ButtonTag = ({content}) => {
    return (
        <button className={`bg-transparent my-2 lg:my-4 mr-4 rounded-20px font-sans px-4 h-auto border-2 border-mbr_pink text-mbr_orange`}>
            <p className="small">
                {content}
            </p> 
        </button>
    )
}

export default ButtonTag
